const admin = [
  {
    path: '/:club_slug/admin/home',
    name: 'admin-home',
    component: () => import('@/views/admin/Home.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/crm',
    name: 'admin-crm',
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
    redirect: { name: 'admin-home' },
  },
  {
    path: '/:club_slug/admin/wage/hourly_wages',
    name: 'admin-wage-hourly-wage',
    component: () => import('@/views/v2-temp/employee/wage-management/index.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/rebate_system/systems',
    name: 'admin-rebate-system-systems',
    component: () => import('@/views/admin/rebate_system/Systems.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/rebate_system/units',
    name: 'admin-rebate-system-units',
    component: () => import('@/views/admin/rebate_system/Units.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/rebate_system/rebates',
    name: 'admin-rebate-system-rebates',
    component: () => import('@/views/admin/rebate_system/Rebates.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/pay_system/systems',
    name: 'admin-pay-system-systems',
    component: () => import('@/views/admin/pay_system/Systems.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/pay_system/units',
    name: 'admin-pay-system-units',
    component: () => import('@/views/admin/pay_system/Units.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/pay_condition/vending_point_conditions',
    name: 'admin-pay-condition-vending-point-conditions',
    component: () => import('@/views/admin/pay_condition/VendingPointConditions.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/pay_condition/sales_conditions',
    name: 'admin-pay-condition-sales-conditions',
    component: () => import('@/views/admin/pay_condition/SalesConditions.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/pay_condition/contribution_ratio_conditions',
    name: 'admin-pay-condition-contribution-ratio-conditions',
    component: () => import('@/views/admin/pay_condition/ContributionRatioConditions.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/pay_condition/vending_size_conditions',
    name: 'admin-pay-condition-vending-size-conditions',
    component: () => import('@/views/admin/pay_condition/VendingSizeConditions.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/pay_condition/referring_size_conditions',
    name: 'admin-pay-condition-referring-size-conditions',
    component: () => import('@/views/admin/pay_condition/ReferringSizeConditions.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/table-filters',
    name: 'admin-table-filters',
    component: () => import('@/views/admin/pay_condition/TableFilters.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/admin/hr/earning-summaries',
    name: 'admin-hr-earning-summaries',
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
    redirect: { name: 'admin-hr-management' },
  },
  {
    path: '/:club_slug/admin/hr/management',
    name: 'admin-hr-management',
    component: () => import('@/views/admin/hr/Management.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },

  // v2
  {
    path: '/:club_slug/admin/audits',
    name: 'admin-audits',
    component: () => import('@/views/admin/Audits.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/eod-closing',
    name: 'eod-closing',
    component: () => import('@/views/v2-temp/eod-closing/index.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/eod-report',
    name: 'eod-report',
    component: () => import('@/views/v2-temp/eod-report/index.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/payrolls',
    name: 'payrolls',
    component: () => import('@/views/v2-temp/employee/payroll/index.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
  {
    path: '/:club_slug/report/expenses',
    name: 'report-expenses',
    component: () => import('@/views/v2-temp/report/expenses/index.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },
]

export default admin
